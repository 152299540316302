import { Button, Modal, Stack, TextInput } from "@mantine/core";
import { useState } from "react";

export function AlertController(props: {
  showAlert: boolean;
  setShowAlert: (open: boolean) => void;
  title: string;
  message?: string;
  placeholder?: string;
  buttonTitle: string;
  onConfirm: (text: string) => void;
}) {
  const [textField, setTextField] = useState("");

  return (
    <Modal
      opened={props.showAlert}
      onClose={() => props.setShowAlert(false)}
      centered={true}
      title={props.title}
      zIndex={10000}
      overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
      radius="md"
    >
      <Stack>
        <TextInput
          data-autofocus
          value={textField}
          placeholder={props.placeholder}
          onChange={(e) => setTextField(e.currentTarget.value)}
          autoFocus
        />
        <Button
          disabled={textField.length === 0}
          fullWidth
          onClick={() => {
            props.setShowAlert(false);
            props.onConfirm(textField);
            setTextField("")
          }}
        >
          {props.buttonTitle}
        </Button>
      </Stack>
    </Modal>
  );
}
