import { Button, Stack, Tabs, Text, TextInput } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconNotes, IconSettings, IconUserCircle } from "@tabler/icons-react";
import { User, updateProfile } from "firebase/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ShowNotificationSuccess } from "../../../components/Notifications";
import LinkedAccounts from "./linked-accounts";

export default function AccountProfile(props: { setPageTitle: (text: string) => void; currentUser: User }) {
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.setPageTitle(t("account.title")),[]);

  const [isLoading, setIsLoading] = useState(false);

  const [newChanges, setNewChanges] = useState({ name: props.currentUser.displayName, email: props.currentUser.email });

  return (
      <Tabs variant="outline" defaultValue="details" radius="md">
        <Tabs.List>
          <Tabs.Tab value="details" leftSection={<IconUserCircle size={18} />}>
            {t("account.tabs.details")}
          </Tabs.Tab>
          <Tabs.Tab value="subscription" leftSection={<IconNotes size={18} />}>
            {t("account.tabs.subscription")}
          </Tabs.Tab>
          <Tabs.Tab value="advanced" leftSection={<IconSettings size={18} />}>
            {t("account.tabs.advanced")}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="details">
          <Stack gap="xl" mt="md">
            <Stack>
              <TextInput
                label={t("general.displayName")}
                value={newChanges.name ?? ""}
                disabled={isLoading}
                radius="md"
                onChange={(e) => setNewChanges({ ...newChanges, name: e.currentTarget.value })}
              />
              <TextInput
                type="email"
                disabled={isLoading}
                label={t("general.email")}
                radius="md"
                value={newChanges.email ?? ""}
                onChange={(e) => setNewChanges({ ...newChanges, email: e.currentTarget.value })}
              />
              <Button
                mt="sm"
                size="md"
                loading={isLoading}
                disabled={
                  newChanges.name === props.currentUser.displayName && newChanges.email === props.currentUser.email
                }
                onClick={() => {
                  setIsLoading(true);
                  if (newChanges.name !== props.currentUser.displayName) {
                    updateProfile(props.currentUser, { displayName: newChanges.name }).then(() => {
                      setIsLoading(false);
                      ShowNotificationSuccess(t("account.details.updated"));
                    });
                  }
                }}
              >
                {t("account.updateDetails")}
              </Button>
            </Stack>
            <LinkedAccounts currentUser={props.currentUser} />
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel value="advanced" mt="lg">
          <Button
            variant="outline"
            color="red"
            fullWidth
            onClick={() =>
              modals.openConfirmModal({
                title: t("account.delete.title"),
                children: <Text>{t("account.delete.message")}</Text>,
                centered: true,
                labels: { confirm: t("account.delete.confirm"), cancel: t("account.delete.cancel") },
                confirmProps: { color: "red" },
                onConfirm() {},
              })
            }
          >
            {t("account.delete.title")}
          </Button>
        </Tabs.Panel>
      </Tabs>
  );
}
