import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { firestore } from "./firebase-config";

// ENDPOINTS
const endpointLibrary = (userId: string) => {
  return collection(firestore, "users", userId, "library");
};

// LIBRARY

export const getUserLibrary = (userId: string, filterBy?: string) => {
  return getDocs(query(endpointLibrary(userId), filterBy ? where("type", "==", filterBy) : orderBy("updated", "desc")));
};

export const createFolder = (userId: string, title: string) => {
  return setDoc(doc(endpointLibrary(userId)), {
    created: serverTimestamp(),
    updated: serverTimestamp(),
    is_collection: true,
    is_in_collection: false,
    title: title,
  });
};

export const updateLibraryItem = (userId: string, id: string, data: {}) => {
  return updateDoc(doc(endpointLibrary(userId), id), {
    ...data,
    updated: serverTimestamp(),
  });
};

export const deleteLibraryItem = (userId: string, id: string) => {
  return deleteDoc(doc(endpointLibrary(userId), id));
};

export const moveLibraryItemFromParent = (userId: string, startId: string, startData: any, endId?: string) => {
  const startDoc = doc(collection(firestore, "users", userId, "library"), startId);
  // destination collection
  const endCollection = collection(firestore, "users", userId, "library", startId, "library");
  // the known destination id
  const endDoc = doc(endCollection, endId ?? "");
  // was endID passed?
  var endDestination = endId ? endDoc : doc(endCollection);

  setDoc(endDestination, {
    created: serverTimestamp(),
    ref: startDoc.path,
    type: startData.type ?? "unknown",
  }).then(() =>
  // update the original item that it is now in a folder
    updateDoc(startDoc, {
      is_in_collection: true,
      updated: serverTimestamp(),
    })
  );
};
