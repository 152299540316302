import { Button, Stack, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function PageNotFound(props: { setPageTitle: (text: string) => void }) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.setPageTitle(t("404.title")),[]);

  return (
    <Stack style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "64vh" }}>
      <Title c="primary" fw={800} fz={48}>
        {t("404.header.title")}
      </Title>
      <Text>{t("404.header.message")}</Text>
      <Button onClick={() => navigate("/")}>{t("404.backHome")}</Button>
    </Stack>
  );
}
