import { Button, Stack, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function UnderMaintenance(props: { setPageTitle: (text: string) => void }) {
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.setPageTitle(t("maintenance.title")), []);

  return (
    <Stack style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "64vh" }}>
      <Title c="primary" fw={800} fz={48}>
        {t("maintenance.title")}
      </Title>
      <Text>{t("maintenance.message")}</Text>
      <Button color="primary.4" mt="xl" size="lg" onClick={() => (window.location.href = "https://tw.socialdummy.app/")}>{t("maintenance.followUs")}</Button>
      <Button color="gray" onClick={() => (window.location.href = "https://apps.apple.com/us/app/social-dummy-notes/id1564113189?ign-itscg=30200&ign-itsct=apps_box_badge")} >Download Social Dummy Notes</Button>
    </Stack>
  );
}
