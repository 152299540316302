/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { User } from "firebase/auth";
import {
  ActionIcon,
  Avatar,
  Divider,
  Flex,
  Menu,
  Skeleton,
  Stack,
  Text,
  Transition,
  UnstyledButton,
  rem,
} from "@mantine/core";
import {
  createFolder,
  deleteLibraryItem,
  getUserLibrary,
  updateLibraryItem,
} from "../../../services/firestore-endpoints";
import { ShowNotificationError } from "../../../components/Notifications";
import { IconDotsVertical, IconFolderFilled, IconFolderPlus, IconPlus, IconUserCircle } from "@tabler/icons-react";
import moment from "moment";
import { ActivityController, ActivityControllerProps } from "../../../components/activity-controller";
import { AlertController } from "../../../components/alert-controller";
// import { useNavigate } from "react-router-dom";

export default function UserLibraries(props: {
  setPageTitle: (text: string) => void;
  currentUser: User;
  setPageNavAction: (item: any[]) => void;
}) {
  const { t } = useTranslation();

  //   const navigate = useNavigate();

  const [userLibrary, setUserLibrary] = useState<any[] | null>();

  useEffect(() => props.setPageTitle(t("libraries.title")), []);

  useEffect(() => {
    reloadLibrary();
  }, [props.currentUser.uid]);

  useEffect(() => {
    props.setPageNavAction([
      <Menu
        key={0}
        trigger="click-hover"
        closeDelay={400}
        position="bottom-end"
        withArrow
        arrowPosition="center"
        arrowSize={12}
        shadow="md"
      >
        <Menu.Target>
          <ActionIcon key={0} radius="xl" size={28} variant="light">
            <IconPlus style={{ width: "80%", height: "80%" }} stroke={2.5} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item leftSection={<IconUserCircle style={{ width: rem(18), height: rem(18) }} />}>
            New Character
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            leftSection={<IconFolderPlus style={{ width: rem(18), height: rem(18) }} />}
            onClick={() => setShowNewFolderAlert(true)}
          >
            {t("libraries.createFolder.short")}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>,
    ]);

    return () => {
      props.setPageNavAction([]);
    };
  }, []);

  const [isDrawOpen, setDrawOpen] = useState(false);
  const [activityDetails, setActivityDetails] = useState<ActivityControllerProps>();

  const [showNewFolderAlert, setShowNewFolderAlert] = useState(false);

  const reloadLibrary = () => {
    setUserLibrary(null);
    getUserLibrary(props.currentUser.uid)
      .then((ss) => setUserLibrary(ss.docs))
      .catch((e) => ShowNotificationError(t("general.error"), e.message));
  };

  return (
    <>
      <AlertController
        showAlert={showNewFolderAlert}
        setShowAlert={setShowNewFolderAlert}
        title={t("libraries.createFolder")}
        buttonTitle={t("general.create")}
        onConfirm={(title: string) => createFolder(props.currentUser.uid, title).then(reloadLibrary)}
      />
      <ActivityController drawOpen={isDrawOpen} setDrawOpen={setDrawOpen} customise={activityDetails!} />
      {/* FOLDERS */}

      <Transition mounted={userLibrary !== null} transition="pop" keepMounted duration={500} timingFunction="ease">
        {(styles) => (
          <Stack style={styles}>
            {userLibrary && userLibrary.sort(function (a, b) {
                if (a.data().is_collection > b.data().is_collection) return -1;
                if (a.data().is_collection < b.data().is_collection) return 1;
                return 0;
              })
              .filter((d) => !d.data().is_in_collection)
              .map((d) => {
                return (
                  <Stack key={d.id}>
                    <Stack>
                      <Flex gap="sm" align="center" justify="space-between">
                        <UnstyledButton>
                          <Flex gap="sm" align="center">
                            {d.data().is_collection ? (
                              <IconFolderFilled style={{ marginLeft: 6, marginRight: 6 }} />
                            ) : (
                              <Avatar size="md" src={d.data()?.avatar ?? ""} />
                            )}
                            <Stack gap={0}>
                              <Text fw={600} lineClamp={1}>
                                {d.data().is_collection
                                  ? d.data()?.title ?? t("general.unknown")
                                  : d.data()?.display_name ?? t("general.unknown")}
                              </Text>
                              <Text fz="xs" fw={300} lineClamp={1} c="dimmed" mt={-4}>
                                {d.data().is_collection
                                  ? `Modified ${moment(d.data().updated.toDate()).format("DD MMM YYYY")}`
                                  : d.data()?.username ?? t("general.unknown")}
                              </Text>
                            </Stack>
                          </Flex>
                        </UnstyledButton>
                        <ActionIcon
                          variant="light"
                          color="primary"
                          mr={8}
                          onClick={() => {
                            setActivityDetails({
                              title: d.data().is_collection
                                ? d.data()?.title ?? t("general.unknown")
                                : d.data()?.display_name ?? t("general.unknown"),
                              subtitle: d.data().is_collection
                                ? `Modified ${moment(d.data().updated.toDate()).format("DD MMM YYYY")}`
                                : d.data()?.username ?? t("general.unknown"),
                              avatar: d.data()?.avatar,
                              icon: d.data().is_collection ? <IconFolderFilled /> : null,
                              rename: {
                                enabled: d.data().is_collection,
                                title: d.data().is_collection ? t("actvitiy.rename.collection") : undefined,
                                existingTitle: d.data().title,
                                onRename(text) {
                                  updateLibraryItem(props.currentUser.uid, d.id, { title: text }).then(reloadLibrary);
                                },
                              },
                              onDelete() {
                                deleteLibraryItem(props.currentUser.uid, d.id).then(reloadLibrary);
                              },
                            });
                            setDrawOpen(true);
                          }}
                        >
                          <IconDotsVertical stroke={1.5} />
                        </ActionIcon>
                      </Flex>
                    </Stack>
                    <Divider my={0} />
                  </Stack>
                );
              })}
          </Stack>
        )}
      </Transition>
      <Transition mounted={userLibrary === null} transition="fade" duration={500} timingFunction="ease">
        {(styles) => (
          <Stack style={styles}>
            {[0, 1, 2, 3, 4, 5, 6].map((i) => {
              return (
                <Flex key={i} gap="sm" align="center" justify="left">
                  <Skeleton circle height={32} />
                  <Skeleton height={6} radius="xl" width="80%" />
                </Flex>
              );
            })}
          </Stack>
        )}
      </Transition>
    </>
  );
}
