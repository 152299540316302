import { getBoolean, getNumber, getString } from "firebase/remote-config";
import { remoteConfig } from "./firebase-config";

export enum RemotePrams {
  TERMS_CONDITIONS = "doc_terms",
  PRIVACY_POLICY = "doc_privacy",
  SLIDESHOW = "slideshow",
}

export const RemoteDefaultConfig: any = {
  slideshow: [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/social-dummy.appspot.com/o/images%2Fsdn_faq.jpg?alt=media&token=b6011fcd-d5ce-4e9c-bd67-f1be22779d50",
      url: "https://support.socialdummy.app",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/social-dummy.appspot.com/o/images%2Fsdn_more.jpg?alt=media&token=c631cb66-1eaf-498d-b2d2-687dee0597c1",
      url: "https://tw.socialdummy.app",
    },
  ],
  doc_terms: [
    {
      title: "General Information",
      section: [
        {
          title: "Social Dummy Products and Services",
          points: [
            "These terms and conditions ('Terms', 'Agreement') are an agreement between Ashley Richards ('Developer', 'us', 'product', 'we' or 'our') and you ('User', 'you' or 'your'). This Agreement sets forth the general terms and conditions of your use of the Social Dummy app, website and any of its products or services (collectively, 'app', 'website', 'products' or 'services'). When you use or access any of our Services, you agree to be bound by these Terms and all applicable laws, rules and regulations. The term “Device” refers to the device which is used to access the Products including but not limited to computers, smartphones and tablets. You may also be asked to click “I accept” at the appropriate place prior to your purchase or of access to the Products. At such time, if you do not click “I accept”, you may not be able to complete a purchase or gain such access. By using the Services, you indicate that you accept these Terms and that you agree to abide by them. If you do not agree to these Terms, please refrain from using any of our Services.<br><br>If you have any questions regarding these Terms or any other concerns about these Services, please contact <a href=\"support@socialdummy.app\">support@socialdummy.app</a>.",
          ],
        },
        {
          title: "Changes to Terms",
          points: [
            "Social Dummy reserves the right to change or update these Terms or any of our policies relating to the app and/or Services at any time. Thus, you are advised to review this page periodically for any changes. Any changes or updates will be effective immediately, or otherwise stated, upon posting on Social Dummy's Twitter account. Your continued use of the Products constitutes your agreement to abide by the Terms as changed.",
          ],
        },
        {
          title: "Basis of License",
          points: [
            "These Terms and the Product set out the whole agreement between you and us for the supply of the Products. In order to participate in certain Products, you may be required to agree to additional terms and conditions; those additional terms are hereby incorporated into these Terms. Where such terms are inconsistent with these Terms, the additional terms shall control.",
            "Please check that the details in these Terms and on the Product are complete and accurate before you use or commit yourself to purchasing the Products. If you think that there is a mistake, please make sure that you ask us to confirm any changes in writing, as we only accept responsibility for statements and representations made in writing by a team member of Social Dummy",
            "AS PART OF YOUR USE OF THE PRODUCTS, YOU AGREE TO THE PROCESSING AND STORAGE OF YOUR PERSONAL INFORMATION IN WESTERN EUROPE, INCLUDING THE PROCESSING AND STORING OF YOUR PERSONAL INFORMATION IN WESTERN EUROPE FOR THE PURPOSES OF PROCESSING PAYMENTS. BY USING THE PRODUCTS, YOU ACKNOWLEDGE THAT YOU UNDERSTAND AND AGREE THAT WESTERN EUROPE MAY NOT HAVE THE SAME LEVEL OF PROTECTIONS FOR YOUR PERSONAL INFORMATION THAT EXISTS IN YOUR COUNTRY OF RESIDENCE, AND YOU NONETHELESS CONSENT TO THE PROCESSING AND STORAGE OF YOUR PERSONAL INFORMATION IN WESTERN EUROPE. WE WILL TAKE MEASURES AS REQUIRED TO COMPLY WITH APPLICABLE LAW REGARDING THE TRANSFER, STORAGE AND USE OF CERTAIN PERSONAL INFORMATION.",
          ],
        },
      ],
    },
    {
      title: "Memberships and Subscriptions",
      section: [
        {
          title: "Becoming a Member",
          points: [
            "You may sign up as a registered user of the Products (a ”Member”). To become a Member you need to go to the relevant section of the Products, then submit your full name and email address, and create a password to be used in conjunction with that email address. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your Device. You may not register for more than one Member account.",
            "In the course of your use of the Products, you may be asked to provide certain personalised information to us (such information is referred to hereinafter as ”User Information”). Our information collection and use policies with respect to the privacy of such User Information are set forth in our Privacy Policy. You acknowledge and agree that you are solely responsible for the accuracy and content of User Information, and you agree to keep it up to date.",
            "By registering for a Social Dummy account, the Products, you warrant that: (i) You are legally capable of entering into binding contracts; (ii) All registration information you submit is truthful and accurate; (iii) You will maintain the accuracy of such information; and (iv) Your use of the Products does not violate any applicable law or regulation.",
            "You must be 13 years of age, or the age of majority in your province, territory or country, to sign up as a registered user of the Services. Individuals under the age of 13, are not allowed to use this Service unless with the involvement and consent of a parent or legal guardian, under such person's account, and otherwise subject to these Terms.",
          ],
        },
        {
          title: "Once a Member",
          points: [
            "You are responsible for maintaining the confidentiality of your account, password and other User Information and for restricting access to your Device to help further protect such information. You are responsible for updating your User Information.",
          ],
        },
        {
          title: "Membership",
          points: [
            "As a Social Dummy Member you will receive access to certain sections, features and functions of the Products that are not available to non-members.",
            "Social Dummy membership accounts and subscriptions are not transferable and therefore you agree to not sell, transfer, or exchange membership accounts or subscriptions in any way or under any circumstance. This expressly applies to any discounted, subsidized, or free accounts or subscriptions.",
            "We may, but have no obligation to, monitor and review accounts activity to verify our services are being used correctly.",
            "You must immediately notify us of any unauthorised uses of your account or any other breaches of security. We will not be liable for omissions.",
          ],
        },
        {
          title: "Subscriptions and Social Dummy Pro",
          points: [
            "Social Dummy account holders may access the Products via ”Social Dummy Pro” (`Pro`), a subscription fee-based program, which gives access to all content within the Products. You will only have access to Pro while your paid subscription is active and subsisting.",
            "We may offer a free trial period of Pro, which automatically converts to a paid annual or monthly subscription term after a certain period of time. In such instances, you can disable the automatic conversion by following the cancellation instructions set forth in section 3 prior to the conversion date.",
            "We may offer a one-time extended trial of Pro for users who meets the requirements from purchases from the legacy app. To qualify, you must have at least purchased 'Unlimited Posts', 'Unlimited Direct Messages', and 'Unlimted Comments & Messages'. When claiming this offer, it will replace the standard introductory offer for new users. You MUST not start the standard introductory offer otherwise will make this offer invalid. To claim the extended trial, you must log in, Account tab, tap (...) then `Migrate From Legacy` and follow the walkthrough. If you qualify for the extended trial, you will be prompted after the migration is complete.",
            "You may access Pro in the following ways: by purchasing a subscription to the Products from within the Apps, where allowed by the App marketplace partners, or through a bundle with one or more of our bundle subscription partners. Please note that if you purchase a subscription through the Apple iTunes Store or our iPhone application, the sale is final, and we will not provide a refund. Your purchase will be subject to Apple's applicable payment policy, which also may not provide refunds. If you purchase a subscription through the Google Play store, the sale is final and we will not provide a refund. Your purchase will be subject to Google's applicable payment policy, which also may not provide refunds. If you purchase through one or more of our bundle subscription partners, the purchase may be further subject to the Terms and Conditions of such partners, and payment and management of the bundle subscription may be administered by them.",
            "You may access Pro via a monthly or annual subscription option. For the purposes of our monthly and yearly subscriptions, a month constitutes 30 calendar days and a year constitutes 365 calendar days.",
            "Our ”Monthly” subscription is paid in monthly instalments. For each month that your monthly subscription is active, you acknowledge and agree that the Apple iTunes Store or Google Play store is authorized to charge the same credit card as was used for the initial subscription fee or another payment method. The monthly renewal subscription fees will continue to be billed to the Payment Method you provided until cancelled. You must cancel your subscription before it renews in order to avoid billing the next month's subscription fee to the Payment Method you provided. Refunds cannot be claimed for any partial-month subscription period.",
            "Our ”Yearly” subscription is paid for by an upfront payment with automatic annual renewals. You acknowledge and agree that the Apple iTunes Store or Google Play store is authorized to charge the Payment Method used for (i) the initial annual subscription fee at the rate secured at the time of purchase, and (ii) the non-discounted renewal subscription fee(s). You must cancel your subscription before it renews in order to avoid billing the renewal subscription fee to the Payment Method you provided. Refunds cannot be claimed for any partial subscription period.",
            "You are responsible for all applicable fees and charges incurred, including applicable taxes, and all subscriptions purchased by you.",
            "In the course of your use of the Products, Social Dummy and its third-party payment service provider may receive and implement updated credit card information from your credit card issuer in order to prevent your subscription from being interrupted by an outdated or invalid card.",
            "You agree not to hold us responsible for banking charges incurred due to payments on your account. If payment is not received from the Payment Method you provided, you agree to pay all amounts due upon demand by them. You agree that you are not permitted to resell any Products purchased through Social Dummy for commercial purposes, unless you have explicit permission from us, or you are an authorised business account holder.",
          ],
        },
        {
          title: "Changing Fees and Charges",
          points: [
            "We reserve the right to change our subscription plans or adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. Except as otherwise expressly provided for in these Terms, any price changes or changes to your subscription plan will take effect following notice to you.",
          ],
        },
      ],
    },
    {
      title: "Cancellation of Services",
      section: [
        {
          title: "Cancellation By You",
          points: [
            "You may cancel a Plan at any time. Cancellation is effective at the end of the applicable monthly or yearly period. \n\nIf you purchase a subscription through the Apple iTunes Store or our iPhone application, you may cancel your subscription by cancelling the automatic renewal of paid In App Subscriptions by selecting Manage App Subscriptions in your iTunes Account settings and selecting the subscription you want to modify. If you purchase a subscription through the Google Play store you may cancel automatic renewals in account settings under Subscriptions in the Google Play app, or according to the current process outlined by Google Play.",
          ],
        },
        {
          title: "Cancellation By Us",
          points: [
            "We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services and we may block your email address to prevent further use of our services.",
            "We may suspend or terminate your use of the Products as a result of your fraud or breach of any obligation under these Terms. Such termination or suspension may be immediate and without notice.",
            "If your account gets blocked or deleted, and you're subscribed to Pro, you may continue paying your subscription. It is your responsibility to cancel your subscription as outlined in 3.1. We can not solely cancel your subscription on your behalf via Apple, Google Play or another third party.",
          ],
        },
        {
          title: "Promotion and Discount Codes",
          points: [
            "Introductory offers are only available to new users of the Products, except where expressly stated otherwise. Previous users or trial users of the Products do not qualify as new users. Unless otherwise set forth in the terms of any promotion, all pricing promotions or discounts will apply to the initial period of the subscription, and any renewals will be charged at the non-discounted rate for the type of subscription purchased.",
          ],
        },
      ],
    },
    {
      title: "Prohibited Use of The Products",
      points: [
        "You agree not to upload, post, email or otherwise send or transmit or introduce any material that contains software viruses or any other computer code, files or programs designed to interrupt, harm, damage, destroy or limit the functionality of any computer software or hardware or equipment linked directly or indirectly with the Products or the Products themselves. You agree not to interfere with the servers or networks underlying or connected to the Products or to violate any of the procedures, policies or regulations of networks connected to the Products. You may not access the Products in an unauthorized manner.",
        "You agree not to impersonate any other person while using the Products, conduct yourself in an offensive manner while using the Products, or use the Products for any illegal, immoral or harmful purpose.",
        "By breaching the provisions of this section 4, you may commit a criminal offence under applicable laws. We may report any such breach to the relevant law enforcement authorities and we may cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the Products will cease immediately.",
        "You agree not to use the Products for any purposes related to scientific research, analysis or evaluation of the Products without express written consent from us.",
      ],
    },
    {
      title: "Availability of Products",
      points: [
        'Although we aim to offer you the best service possible, we make no promise that the Products will meet your requirements and we cannot guarantee that the Products will be fault free. If a fault occurs in the Products, please report it to us at <a href="mailto:support@socialdummy.app">support@socialdummy.app</a> and we will review your complaint and, where we determine it is appropriate to do so, correct the fault. If the need arises, we may suspend access to the Products while we address the fault. We will not be liable to you if the Products are unavailable for a commercially reasonable period of time.',
        "Your access to the Products may be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or Products. We will restore the Products as soon as we reasonably can.",
      ],
    },
    {
      title: "",
      points: [
        "ou have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all images, documents and text.",
        "All your stories, uploaded images and content does not guarantee lifetime availability. If in the future Social Dummy services were to cease, all data will be deleted. No compensation or refund of any kind will be given.",
        "We do not own any data, information or material (”Content”) that you enter or create in the app in the course of using the Service. We are not responsible for the Content created using the Service. You create the content at your own risk and you won't hold us liable in any way. We may, but have no obligation to, monitor and review Content in the app submitted or created using our Services by you. Unless specifically permitted by you, your use of the app does not grant us the license to use, reproduce, adapt, modify, publish or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose. But you grant us permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable.",
        'Images uploaded from the application are stored in Firebase so you can retrieve your images when needed in the app. If an image fails to upload to Firebase for any reason, the image may then be uploaded to our private <a href="https://imgBB.com">ImgBB.com</a> account where they are stored and remain private only to the user. We still reserve the right, though not the obligation, to, in our own sole discretion, remove any Content uploaded to our ImgBB account that, in our reasonable opinion, violates any of our policies or is in any way harmful or objectionable.',
      ],
    },
    {
      title: "Accuracy of Information",
      points: [
        "Occasionally there may be information in the app that contains typographical errors, inaccuracies or omissions that may relate to promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information if any information in the app, website or on any related Service is inaccurate at any time without prior notice.",
        "We undertake no obligation to update, amend or clarify information in the app including, without limitation, pricing information, except as required by law.",
        "No specified update or refresh date applied in the app should be taken to indicate that all information in the app or on any related Service has been modified or updated.",
      ],
    },
    {
      title: "Uptime Guarantee",
      points: [
        "The uptime guarantee is dependent upon Firebase's services.",
        "We are not responsible for sudden outages that are or may be caused as a result of Firebase's services.",
        "The uptime guarantee does not apply to service interruptions caused by periodic scheduled maintenance or repairs we may undertake from time to time; interruptions caused by you or your activities; outages that do not affect core Service functionality; causes beyond our control or that are not reasonably foreseeable; and outages related to the reliability of certain programming environments.",
      ],
    },
    {
      title: "External Links",
      points: [
        "We may provide links to other websites or services for you to access. You acknowledge that any access is at your sole discretion and for your information only. We do not review or endorse any of those websites or services. We are not responsible in any way for:(a) the availability of, (b) the privacy practices of, (c) the content, advertising, products, goods or other materials or resources on or available from, or (d) the use to which others make of these other websites or services. We are also not responsible for any damage, loss or offence caused or alleged to be caused by, or in connection with, the use of or reliance on such websites or services.",
        'You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists. You must not establish a link from any website that is not owned by you. The Products must not be framed on any other website, nor may you create a link to any part of the Products unless you have written permission to do so from Social Dummy. We reserve the right to withdraw linking permission with written notice. The website from which you are linking must comply in all respects with the content standards set out in our acceptable use policy. If you wish to make any use of material on or in the Products other than that set out above, please address your request to <a href="mailto:support@socialdummy.app">support@socialdummy.app</a>.',
      ],
    },
    {
      title: "Products Disclaimer",
      points: [
        "The information contained in the Products is for general information purposes only. While we endeavour to keep the information up-to-date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the Products or the information contained on the Products for any purpose. Any reliance you place on such information is therefore strictly at your own risk.",
      ],
    },
    {
      title: "Intellectual Property Rights",
      points: [
        "This Agreement does not transfer to you any intellectual property owned by us or third parties, and all rights, titles, and interests in and to such property will remain (as between the parties) solely with us.",
        "All trademarks, service marks, graphics and logos used in connection with our app or Services, are trademarks or registered trademarks of ours or our licensors.",
        "Other trademarks, service marks, graphics and logos used in connection with our app or Services may be the trademarks of other third parties.",
        "Your use of our app grants you no right or license to reproduce or otherwise use our or third-party trademarks.",
      ],
    },
    {
      title: "Support Use",
      points: [
        "When getting in contact via our support site, responses to your queries are dependent upon the quality of the message you send. Please give as much detail as you can to help aid and answer your query. Once we have responded to you, it is your responsibility to reply within two weeks. Failure to respond or have an antique question about the related Service will result in your message being discarded or being resolved. However, if you reply to your message after two weeks have passed, your message will reopen and we will continue to investigate and/or assist you with your enquiry. Any further messages will continue to require a response within the two-week window.",
      ],
    },
    {
      title: "Disclaimer of Warranty",
      points: [
        "You agree that your use of our App or Services is solely at your own risk.",
        "You agree that such Service is provided on an ”as is” and ”as available” basis.",
        "We expressly disclaim all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement.",
        "We make no warranty that the Services will meet your requirements, or that the Service will be uninterrupted, timely, secure, or error-free; nor do we make any warranty as to the results that may be obtained from the use of the Service or as to the accuracy or reliability of any information obtained through the Service or that defects in the Service will be corrected.",
        "You understand and agree that any material and/or data downloaded or otherwise obtained through the use of Service is done at your own discretion and risk and that you will be solely responsible for any damage to your computer system or loss of data that results from the download of such material and/or data.",
        "We make no warranty regarding any goods or services purchased or obtained through the Service or any transactions entered into through the Service.",
        "No advice or information, whether oral or written, obtained by you from us or through the Service shall create any warranty not expressly made herein.",
      ],
    },
    {
      title: "Limitation of Liability",
      points: [
        "To the fullest extent permitted by applicable law, in no event will the Developer, its affiliates, officers, directors, employees, agents, suppliers or licensors be liable to any person for (a): any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use of the content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if we have been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent permitted by applicable law, the aggregate liability of us and its affiliates, officers, employees, agents, suppliers and licensors, relating to the services will be limited to an amount greater than one British pound sterling or any amounts actually paid by you to us for the prior one month period prior to the first event or occurrence giving rise to such liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or failure of its essential purpose.",
      ],
    },
    {
      title: "Dispute Resolution",
      points: [
        "The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the substantive and procedural laws of Sussex, United Kingdom without regard to its rules on conflicts or choice of law and, to the extent applicable, the laws of United Kingdom. The exclusive jurisdiction and venue for actions related to the subject matter hereof shall be the state and federal courts located in Sussex, United Kingdom, and you hereby submit to the personal jurisdiction of such courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.",
      ],
    },
  ],
  doc_privacy: [
    {
      title: "Social Dummy Products and Services",
      points: [
        "This privacy policy (”Policy”) describes how Ashley Richards ('Developer', 'us', 'we' or 'our') collects, protects and uses the personally identifiable information (”Personal Information”) you ('User', 'you' or 'your') may provide in the Social Dummy app and any of its services (collectively, 'app', 'website', or 'services'). This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.<br>If you choose to use our app, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the app. We will not use or share your information with anyone, unless explicitly stated, except as described in this Policy. The terms used in this Policy have the same meanings as in our <a href=\"http://terms.socialdummy.app\">Terms and Conditions</a>, which are accessible unless otherwise defined in this Policy.<br><br>If you have any questions about this Policy, please contact <a href=\"support@socialdummy.app\">support@socialdummy.app</a>.",
      ],
    },
    {
      title: "Data Collection and Use",
      points: [
        "No analytics or usage data are used to track movement around the app.<br><br>However, if the app were to crash, there may be some logs associated when crash data has been sent to us. We can not guarantee that any Personal Information will be included in the logs. If there are, these details would never be shared with any external partners or licensors.<br>For a better experience, while using the app, you may provide us with certain personally identifiable information like your full name. The information that is requested may be uploaded to the server. However, if you do not wish to enter your full name then do not enter any Personal Information.",
      ],
    },
    {
      title: "Device Permissions",
      points: [
        "Depending on your device, the app may request certain permissions that allow it to access the User's device Data as described below. By default, these permissions must be granted by the User before the respective information can be accessed. Once permission has been given, it can be revoked by the User at any time. In order to revoke these permissions, Users may refer to the device settings.<br>The exact procedure for controlling app permissions is dependent on the User's device and software.<br>Please note that the revoking of such permissions might impact the proper functioning of this Application.<br>If you grant us any of the permissions listed below, the respective Personal Data may be processed (i.e accessed to, modified or removed) by the app.",
        "Camera Usage - Used for taking photos to use in your content.",
        'Photo Library Usage - Used for accessing your device photo library where you can import your own photos when requested and may be uploaded to our servers as outlined in our <a href="http://terms.socialdummy.app">Terms and Conditions</a>.',
        "Push Notification Usage - Used to send urgent/critical notifications about the Service or is part of the functionality of the app.",
      ],
    },
    {
      title: "Application Providers",
      points: [
        "I may employ third-party companies and/or individuals due to the following reasons:<br>To facilitate the app;<br>To provide the app on our behalf;<br>To perform app-related apps; or<br>To assist us in analyzing how our app is used.",
        "We want to inform users of this app that these third parties may have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.",
      ],
    },
    {
      title: "Security",
      points: [
        "We value your trust in providing us with your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security but relies on Firebase's security.",
      ],
    },
    {
      title: "Links to Other Sites",
      points: [
        "The app may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by me. Therefore, I highly advise you to review the Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or apps.",
      ],
    },
    {
      title: "Children's Privacy",
      points: [
        "We do not knowingly collect any Personal Data from children. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us and we will delete such information from our files within a reasonable period of time.",
      ],
    },
    {
      title: "Support Enquires",
      points: [
        "We receive and store any information you knowingly provide to us when you contact us via our support desk. This information may include your name (if provided), email address, device details such as device version, operating system type and version, language preferences and other statistics that are intended to aid the investigation into your query. Once resolved, tickets are deleted within 28 days along with the collected data of the ticket.",
      ],
    },
    {
      title: "Legal Disclosure",
      points: [
        "We will disclose any information we collect, use or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request. In the event we go through a business transition, such as a merger or acquisition by another company, or the sale of all or a portion of its assets, your user account, and personal data will likely be among the assets transferred.",
      ],
    },
    {
      title: "Changes and Amendments",
      points: [
        'We reserve the right to modify this policy relating to the app at any time. Thus, you are advised to review this page periodically for any changes. We will notify you of any updates by posting an update on <a href="https://tw.socialdummy.app/">Twitter (@ARSocialDummy)</a> or on <a href="http://news.ashleyrichards.co.uk">news.ashleyrichards.co.uk</a>.',
      ],
    },
  ],
};

export function getRemoteString(key: RemotePrams): string {
  return getString(remoteConfig, key);
}
export function getRemoteNumber(key: RemotePrams): number {
  return getNumber(remoteConfig, key);
}

export function getRemoteBoolean(key: RemotePrams): boolean {
  return getBoolean(remoteConfig, key);
}

export function getRemoteJSON(key: RemotePrams): any {
  return JSON.parse(getString(remoteConfig, key));
}
