import { useEffect, useState } from "react";
import { AppShell, Burger, Flex, Group, Loader, Text, Transition } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { isDesktop } from "react-device-detect";
import MobileIndex from "./platform/mobile";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthLogin from "./platform/shared/auth/login";
import { auth } from "./services/firebase-config";
import { User } from "firebase/auth";
import PageNotFound from "./platform/shared/page-not-found";
import { SideMenu } from "./components/side-bar";
import AccountProfile from "./platform/shared/account/account";
import { useTranslation } from "react-i18next";
import TermsAndConditions from "./platform/shared/documents/terms";
import { RemotePrams } from "./services/firebase-remote-config";
import UserLibraries from "./platform/shared/libraries/library";
import UnderMaintenance from "./platform/shared/under-maintenance";

export default function App() {
  const { t } = useTranslation();

  const [drawOpened, { toggle: drawToggle }] = useDisclosure();

  const [isLoading, setIsLoading] = useState(true);
  const [hideMenu, setHideMenu] = useState(false);
  const [pageTitle, setPageTitle] = useState(t("title"));
  const [pageNavActions, setPageNavActions] = useState<any[]>();

  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  // const [currentUserToken, setCurrentUserToken] = useState("");

  let siteUnderMaintence = true;

  // Track whether the user is logged in or not
  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((tokenId) => {
          // setCurrentUserToken(tokenId);
          setCurrentUser(user);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    document.title = `${pageTitle} - ${t("title")}`;
    setPageTitle(pageTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTitle]);

  if (isLoading)
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Loader size="xl" color="primary.5" />
      </div>
    );

  return (
    <Router>
      <AppShell
        layout={isDesktop ? "alt" : "default"}
        header={{ height: 72 }}
        navbar={{
          width: siteUnderMaintence ? 0 : isDesktop ? 350 : "100%",
          breakpoint: "sm",
          collapsed: { mobile: !drawOpened },
        }}
        padding="md"
      >
        {/* HEADER */}

        <AppShell.Header p="lg" withBorder>
          <Flex align="center" justify="space-between">
            <Text fw={800} size="28px" c="primary.4" lineClamp={1}>
              {pageTitle}
            </Text>
            <Group gap="lg">
              <Transition mounted={!drawOpened} transition="slide-left" timingFunction="ease">
                {(styles) => <Group style={styles}>{pageNavActions}</Group>}
              </Transition>
              <Burger
                opened={drawOpened}
                hidden={siteUnderMaintence ? true : hideMenu}
                onClick={drawToggle}
                hiddenFrom="sm"
                size="md"
                color="primary.4"
              />
            </Group>
          </Flex>
        </AppShell.Header>

        {/* NAVIGATION */}

        {!siteUnderMaintence && (
          <AppShell.Navbar hidden={hideMenu}>
            <SideMenu
              currentUser={currentUser}
              setIsLoading={(loading) => setIsLoading(loading)}
              toogleDraw={drawToggle}
            />
          </AppShell.Navbar>
        )}

        {/* MAIN */}

        <AppShell.Main>
          <Routes>
            {/* home */}

            <Route
              path="/"
              element={
                siteUnderMaintence ? (
                  <UnderMaintenance setPageTitle={setPageTitle} />
                ) : (
                  <>
                    {/* <BrowserView></BrowserView>
                  <MobileView> */}
                    <MobileIndex setPageTitle={setPageTitle} />
                    {/* </MobileView> */}
                  </>
                )
              }
            />

            {/* AUTH */}
            {!siteUnderMaintence && !currentUser && (
              <Route path="/login" element={<AuthLogin setHideMenu={setHideMenu} setPageTitle={setPageTitle} />} />
            )}

            {currentUser && (
              <Route
                path="/account"
                element={<AccountProfile setPageTitle={setPageTitle} currentUser={currentUser} />}
              />
            )}
            {currentUser && (
              <Route
                path="/libraries"
                element={
                  <UserLibraries
                    setPageTitle={setPageTitle}
                    currentUser={currentUser}
                    setPageNavAction={setPageNavActions}
                  />
                }
              />
            )}

            <Route
              path="/privacy-policy"
              element={
                <TermsAndConditions
                  dataRemotePram={RemotePrams.PRIVACY_POLICY}
                  pageTitle={t("privacy.title")}
                  setPageTitle={setPageTitle}
                />
              }
            />
            <Route
              path="/terms-of-use"
              element={
                <TermsAndConditions
                  dataRemotePram={RemotePrams.TERMS_CONDITIONS}
                  pageTitle={t("tandc.title")}
                  setPageTitle={setPageTitle}
                />
              }
            />
            <Route path="*" element={<PageNotFound setPageTitle={setPageTitle} />} />
          </Routes>
        </AppShell.Main>
      </AppShell>
    </Router>
  );
}
