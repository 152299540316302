import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { IS_IN_DEVELOPMENT } from "./functions-origin";
import { fetchAndActivate, getRemoteConfig } from "firebase/remote-config";
import { RemoteDefaultConfig } from "./firebase-remote-config";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBV0CP3c_zssrKOEKlQLlLxqFDBNHzDn_g",
  authDomain: "social-dummy.firebaseapp.com",
  databaseURL: "https://social-dummy.firebaseio.com",
  projectId: "social-dummy",
  storageBucket: "social-dummy.appspot.com",
  messagingSenderId: "61260660932",
  appId: "1:61260660932:web:380e19814ce09df969c991",
  measurementId: "G-9M24N3VWG4",
};

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

// Initialize Firebase
export const app = ((window.FIREBASE_APPCHECK_DEBUG_TOKEN = IS_IN_DEVELOPMENT), initializeApp(firebaseConfig));

initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6Ld1R10pAAAAAI40mtqgiODGNoqVmIH9cl5AHlW_"),
  isTokenAutoRefreshEnabled: true,
});

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const database = getDatabase(app);

export const analytics = getAnalytics(app);
export const logAnalytic = (message: string) => {
  logEvent(analytics, message);
};

// Initialize Remote Config and get a reference to the service
export const remoteConfig = getRemoteConfig(app);
// Determine how long data is cached - in milisecs
remoteConfig.settings.minimumFetchIntervalMillis = 10000;
// Assign the default values
remoteConfig.defaultConfig = RemoteDefaultConfig;
// Get latest from server
fetchAndActivate(remoteConfig);
