import { Stack, Title, Flex, Paper, Button, ActionIcon, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconInfoSquareRoundedFilled, IconLink, IconUnlink } from "@tabler/icons-react";
import {
  GoogleAuthProvider,
  OAuthProvider,
  User,
  getRedirectResult,
  linkWithRedirect,
  unlink,
} from "firebase/auth";
import { useTranslation } from "react-i18next";
import { ShowNotificationError, ShowNotificationWarning } from "../../../components/Notifications";
import { useState } from "react";
import { auth } from "../../../services/firebase-config";

export default function LinkedAccounts(props: { currentUser: User }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const availableProviders = ["Apple", "Google", "Password"];

  const linkAccount = async (provider: OAuthProvider | GoogleAuthProvider) => {
    setIsLoading(true);
    await linkWithRedirect(props.currentUser, provider);
    getRedirectResult(auth)
      .then(() => window.location.reload())
      .catch((e) => {
        setIsLoading(false);
        ShowNotificationError(t("general.error"), e.message);
      });
  };

  const accounts = availableProviders.map((p) => {
    const providerConnected = props.currentUser.providerData.filter((pd) => pd.providerId.includes(p.toLowerCase()));

    const isPasswordConnected = providerConnected[0]?.providerId === "password";

    return (
      <Paper key={p} withBorder px="md" py="sm" radius="md">
        <Flex align="center" justify="space-between">
          <Title order={3} fw="bold" style={{ textTransform: "capitalize" }}>
            {p.split(".")[0]}
          </Title>
          {!isPasswordConnected &&
            (providerConnected.length === 1 ? (
              <Button
                variant="outline"
                leftSection={<IconUnlink />}
                radius="xl"
                color="red"
                loading={isLoading}
                onClick={() =>
                  modals.openConfirmModal({
                    title: t("account.unlink.confirm.title", { provider: p }),
                    labels: { confirm: t("account.unlink"), cancel: t("general.cancel") },
                    confirmProps: { color: "red" },
                    closeOnClickOutside: false,
                    withCloseButton: false,
                    centered: true,
                    onConfirm() {
                      setIsLoading(true);
                      unlink(props.currentUser, `${p.toLowerCase()}.com`)
                        .then(() => window.location.reload())
                        .catch((e) => {
                          setIsLoading(false);
                          ShowNotificationError(t("general.error"), e.message);
                        });
                    },
                  })
                }
              >
                {t("account.unlink")}
              </Button>
            ) : (
              <Button
                radius="xl"
                color="primary.4"
                leftSection={<IconLink />}
                loading={isLoading}
                onClick={() => {
                  switch (p) {
                    case "Apple": {
                      const pov = new OAuthProvider("apple.com");
                      pov.addScope("email");
                      pov.addScope("name");
                      linkAccount(pov);
                      break;
                    }
                    case "Google": {
                      linkAccount(new GoogleAuthProvider());
                      break;
                    }
                    case "Password": {
                      ShowNotificationWarning("TO DO");
                      break;
                    }
                    default:
                      ShowNotificationError(t("account.link.error.title"), t("account.link.error.message"));
                      break;
                  }
                }}
              >
                {t("account.link")}
              </Button>
            ))}
          {isPasswordConnected && (
            <ActionIcon
              color="primary.4"
              size="md"
              radius="xl"
              variant="transparent"
              onClick={() =>
                modals.open({
                  title: t("account.password.title"),
                  children: <Text>{t("account.password.message")}</Text>,
                  centered: true,
                })
              }
            >
              <IconInfoSquareRoundedFilled size={24} style={{ width: 28, height: 28 }} />
            </ActionIcon>
          )}
        </Flex>
      </Paper>
    );
  });

  return (
    <Stack>
      <Title order={2} c="primary.4">
        {t("account.linkedAccounts")}
      </Title>
      {accounts}
    </Stack>
  );
}
