import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import { createTheme, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import "./i18n";

const theme = createTheme({
  fontFamily: "Rubik, sans-serif",
  primaryColor: "primary",
  colors: {
    primary: [
      "#e8f6fd",
      "#b9e3f9",
      "#8ad0f5",
      "#5bbdf1",
      "#2caaec",
      "#1391d3",
      "#0e70a4",
      "#0a5075",
      "#063046",
      "#021017",
    ],
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <MantineProvider theme={theme} defaultColorScheme="auto">
      <ModalsProvider>
        <Notifications position="top-center" zIndex={2077} limit={1} />
        <App />
      </ModalsProvider>
    </MantineProvider>
  </React.StrictMode>
);
