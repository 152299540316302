/* eslint-disable no-lone-blocks */
import { ActionIcon, Avatar, Divider, Drawer, Flex, Stack, Text, UnstyledButton } from "@mantine/core";
import { IconFolderSymlink, IconFolderX, IconPencil, IconTrash, IconX } from "@tabler/icons-react";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { modals } from "@mantine/modals";
import { AlertController } from "./alert-controller";

export interface ActivityControllerProps {
  title?: string;
  subtitle?: string;
  icon?: ReactElement | null;
  avatar?: string;
  rename?: {
    enabled: boolean;
    title?: string;
    existingTitle?: string;
    onRename: (text: string) => void;
  };
  move?: {
    enabled: boolean;
    onMove: (destinationId: string) => void;
  };
  onRemoveFromFolder?: () => void;
  onDelete?: () => void;
}

export function ActivityController(props: {
  drawOpen: boolean;
  setDrawOpen: (opended: boolean) => void;
  customise: ActivityControllerProps;
}) {
  const { t } = useTranslation();

  const [isRenameOpen, setRenameOpen] = useState(false);

  return (
    <>
      <AlertController
        showAlert={isRenameOpen}
        setShowAlert={setRenameOpen}
        title={props?.customise?.rename?.title ?? t("activity.rename")}
        placeholder={props?.customise?.rename?.existingTitle}
        buttonTitle={t("activity.rename")}
        onConfirm={(text) => {
          setRenameOpen(false);
          props.setDrawOpen(false);
          props.customise.rename?.onRename(text);
        }}
      />
      <Drawer
        opened={props.drawOpen}
        onClose={() => props.setDrawOpen(false)}
        position="bottom"
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
        withCloseButton={false}
        size={props?.customise?.rename?.enabled ? "31%" : "22%"}
      >
        <Stack>
          {/* HEADER */}
          <Flex gap="sm" align="center" justify="space-between">
            <Flex gap="sm" align="center">
              {props.customise?.icon}
              {!props.customise?.icon && <Avatar size="md" src={props.customise?.avatar ?? ""} />}
              <Stack gap={0}>
                <Text fw={600} lineClamp={1}>
                  {props.customise?.title}
                </Text>
                {props.customise?.subtitle && (
                  <Text fz="xs" fw={300} lineClamp={1} c="dimmed" mt={-4}>
                    {props.customise?.subtitle}
                  </Text>
                )}
              </Stack>
            </Flex>
            <ActionIcon variant="transparent" color="dimmed" onClick={() => props.setDrawOpen(false)}>
              <IconX />
            </ActionIcon>
          </Flex>
          <Divider />

          {/* RENAME */}
          {props.customise?.rename?.enabled && props.customise?.rename?.onRename! && (
            <ActivityControllerItem
              title={props?.customise?.rename?.title ?? t("activity.rename")}
              icon={IconPencil}
              onClick={() => setRenameOpen(true)}
            />
          )}

          {/* FOLDER MOVE */}
          {props?.customise?.move?.enabled && props?.customise?.move?.onMove! && (
            <ActivityControllerItem title="" icon={IconFolderSymlink} onClick={() => {}} />
          )}

          {/* REMOVE FROM FOLDER */}
          {props?.customise?.onRemoveFromFolder && (
            <ActivityControllerItem title="" icon={IconFolderX} onClick={() => {}} />
          )}

          {/* DELETE */}
          {props.customise?.onDelete && (
            <ActivityControllerItem
              title={t("activity.delete")}
              icon={IconTrash}
              color="#ff8787"
              onClick={() => {
                props.setDrawOpen(false);
                modals.openConfirmModal({
                  title: `${t("activity.delete")} '${props.customise?.title}'?`,
                  children: <Text>{t("activity.delete.message", { item: props.customise?.title })}</Text>,
                  centered: true,
                  labels: { confirm: t("activity.delete"), cancel: t("general.cancel") },
                  confirmProps: { color: "red" },
                  withCloseButton: false,
                  onConfirm: props.customise.onDelete,
                });
              }}
            />
          )}
        </Stack>
      </Drawer>
    </>
  );
}

export function ActivityControllerItem(props: { title: string; icon: any; color?: string; onClick: () => void }) {
  return (
    <Stack>
      <UnstyledButton onClick={props.onClick}>
        <Flex gap="sm" align="center" justify="space-between">
          <Flex gap="sm" align="center">
            <props.icon style={{ marginLeft: 6, marginRight: 6, color: props?.color }} />
            <Text c={props?.color} fw={600} lineClamp={1}>
              {props.title}
            </Text>
          </Flex>
        </Flex>
      </UnstyledButton>
      <Divider />
    </Stack>
  );
}
