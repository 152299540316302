import { notifications } from "@mantine/notifications";
import {
  IconAlertTriangleFilled,
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconInfoCircleFilled,
} from "@tabler/icons-react";

export function ShowNotificationSuccess(title: string, description?: string, onClose?: () => void) {
  notifications.clean();
  notifications.show({
    title: title,
    message: description,
    icon: <IconCircleCheckFilled />,
    radius: "lg",
    autoClose: 2500,
    onClose: onClose,
    withCloseButton: false,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.teal[6],
        borderColor: theme.colors.teal[6],

        "&::before": { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": { backgroundColor: theme.colors.teal[7] },
      },
    }),
  });
}

export function ShowNotificationWarning(title: string, description?: string, onClose?: () => void) {
  notifications.clean();
  notifications.show({
    title: title,
    message: description,
    icon: <IconAlertTriangleFilled />,
    radius: "lg",
    autoClose: 2500,
    onClose: onClose,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.orange[6],
        borderColor: theme.colors.orange[6],

        "&::before": { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": { backgroundColor: theme.colors.orange[7] },
      },
    }),
  });
}

export function ShowNotificationInfo(title: string, description?: string, onClose?: () => void) {
  notifications.clean();
  notifications.show({
    title: title,
    message: description,
    icon: <IconInfoCircleFilled color="white" />,
    radius: "lg",
    autoClose: 2500,
    onClose: onClose,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.white[6],
        borderColor: theme.colors.white[6],

        "&::before": { backgroundColor: theme.white },
      },

      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": { backgroundColor: theme.colors.white[7] },
      },
    }),
  });
}

export function ShowNotificationError(title: string, description?: string, onClose?: () => void) {
  notifications.clean();
  notifications.show({
    title: title,
    message: description,
    icon: <IconCircleXFilled />,
    radius: "lg",
    autoClose: 6000,
    withCloseButton: true,
    onClose: onClose,
    styles: (theme) => ({
      root: {
        backgroundColor: theme.colors.red[6],
        borderColor: theme.colors.red[6],
        "&::before": { backgroundColor: theme.white },
      },
      title: { color: theme.white },
      description: { color: theme.white },
      closeButton: {
        color: theme.white,
        "&:hover": { backgroundColor: theme.colors.red[7] },
      },
    }),
  });
}
